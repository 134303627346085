import React from "react"
import Layout from "../components/layout"

export default () => (
  <Layout title="Contato">
    <div className="row">
      <div className="contact-wrapper">
        <h1 className="center background-title">Contato</h1>

        <div className="global-inner">
          <div className="col s12 center">
            <p>Segunda à Sexta - 6:00 às 22:00h</p>
            <p>Sábado - 9:00 às 15:00h</p>
            <p>Domingos e Feriados - 9:00 às 12:00h</p>

            <p>
              <a
                href="mailto:hommerfitness@gmail.com"
                alt="Academia HommerFitness Email"
              >
                hommerfitness@gmail.com
              </a>
            </p>
          </div>
        </div>

        <div className="col s12">
          <div className="unit-map">
            <div className="map-block col s12">
              <p className="center">(11) 2011-8699</p>
              <p className="center">
                <a
                  href="https://wa.me/5511958546063"
                  target="_blank"
                  rel="noreferrer"
                >
                  Whatsapp: (11) 95854-6063
                </a>
              </p>
              <iframe
                title="Google Maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.35827451742!2d-46.48878218569395!3d-23.59148058466792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce67b7f349e807%3A0xc6cc537f8c9cb60f!2sR.+Andr%C3%A9+de+Almeida%2C+711+-+Cidade+S%C3%A3o+Mateus%2C+S%C3%A3o+Paulo+-+SP!5e0!3m2!1spt-BR!2sbr!4v1473623573212"
                className="col s12"
                width="600"
                height="300"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
